var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"task-detail",class:{ mobile: _vm.isMobile }},[_c('v-header',{attrs:{"back":_vm.onBack,"backText":_vm.$t('task.backList')}}),_c('div',{staticClass:"container px"},[_c('div',{staticClass:"user"},[_c('v-avatar',{staticClass:"avatar",attrs:{"src":_vm.task.user_info && _vm.task.user_info.avatar},on:{"click":function($event){_vm.task.user_info && _vm.$router.push(_vm.$paths.users + _vm.task.user_info.id)}}}),_c('div',{staticClass:"name"},[_c('span',{on:{"click":function($event){_vm.task.user_info && _vm.$router.push(_vm.$paths.users + _vm.task.user_info.id)}}},[_vm._v(" "+_vm._s((_vm.task.user_info && _vm.task.user_info.nickname) || "Angry Miao")+" ")]),_c('div',{staticClass:"time"},[(_vm.task.start_at)?[_vm._v(" "+_vm._s(_vm._f("timeFormat")(_vm.task.start_at))+" ")]:_vm._e(),(_vm.task.start_at && _vm.task.end_at)?[_vm._v(" - ")]:_vm._e(),(_vm.task.end_at)?[_vm._v(" "+_vm._s(_vm._f("timeFormat")(_vm.task.end_at))+" ")]:_vm._e()],2)]),(
          (_vm.allow('task', 'update') && _vm.task.user_id === _vm.user.id) ||
            _vm.allow('task', 'update_other_user_data')
        )?_c('md-button',{staticClass:"button",on:{"click":function($event){return _vm.$router.push(_vm.$paths.taskCreate + _vm.task.id + '/')}}},[_c('i',{staticClass:"iconfont icon-bianji"}),_vm._v(_vm._s(_vm.task.status === "closed" ? _vm.$t("task.reopen") : _vm.$t("task.edit"))+" ")]):_vm._e(),(
          ((_vm.allow('task', 'update') && _vm.task.user_id === _vm.user.id) ||
            _vm.allow('task', 'admin_closed_task')) &&
            _vm.task.status === 'processing'
        )?_c('md-button',{staticClass:"button",on:{"click":_vm.onDone}},[_c('i',{staticClass:"iconfont icon-circle-check"}),_vm._v(_vm._s(_vm.$t("task.close"))+" ")]):_vm._e(),_c('v-share',{staticClass:"share",attrs:{"title":_vm.task.title}},[_c('i',{staticClass:"iconfont icon-fenxiang"})]),(_vm.task.status === 'closed')?_c('i',{staticClass:"status iconfont icon-circle-check"}):_vm._e()],1),_c('div',{staticClass:"title"},[_vm._v("[#"+_vm._s(_vm.task.id)+"] "+_vm._s(_vm.task.title))]),_c('div',{staticClass:"content ql-editor",domProps:{"innerHTML":_vm._s(_vm.task.content)}}),_c('div',{staticClass:"users-tags"},[(_vm.task.mention_user_ids && _vm.task.mention_user_ids.length)?_c('v-mentioned-users',{attrs:{"users":_vm.task.mention_user_info}}):_vm._e(),(_vm.task.tags && _vm.task.tags.length)?_c('v-task-tags',{attrs:{"tags":_vm.task.tags}}):_vm._e()],1),(_vm.task.vote_conf.enable)?_c('v-vote',{attrs:{"task":_vm.task,"value":_vm.task.vote_content},on:{"vote":_vm.onVote}}):_vm._e(),_c('div',{staticClass:"info"},[(_vm.task.max_reward_number)?_c('div',{staticClass:"coin"},[_c('img',{staticClass:"icon",attrs:{"src":_vm.images.miao_coin,"alt":""}}),_vm._v(" "+_vm._s(_vm.$t("task.distribute"))+"： "),_c('span',[_vm._v(_vm._s(_vm.task.max_reward_number)+" MIAO COIN")])]):_vm._e(),_c('div',{staticClass:"chain"},[_vm._v(_vm._s(_vm.chainName))]),(_vm.group.length)?_c('div',{staticClass:"group"},[_vm._v(" "+_vm._s(_vm.$t("task.public"))+"： "),_c('v-user-group',{attrs:{"group":_vm.group}})],1):_vm._e(),_c('div',{staticClass:"time"},[_vm._v(" "+_vm._s(_vm.$t("task.createTime"))+"： "+_vm._s(_vm._f("timeFormat")(_vm.task.create_time))+" ")])]),(_vm.task.id)?_c('v-task-rewards',{attrs:{"taskId":_vm.task.id,"task":_vm.task}}):_vm._e(),(_vm.task.id)?_c('v-task-comments',{attrs:{"taskId":_vm.task.id,"task":_vm.task}}):_vm._e()],1),_c('v-footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }