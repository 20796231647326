




































































































import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import { share, download } from "@/utils";
import { dispatch, Task, Group, TasksChain } from "@/store";

export default Vue.extend({
  data() {
    return {
      task: { vote_conf: { enable: false } } as Task,
      groupIds: [] as number[],
      isFormTasks: false
    };
  },
  computed: {
    ...mapGetters({ allow: "allow" }),
    ...mapState({ user: "user" }),
    ...mapState("app", {
      isCN: "isCN",
      isMobile: "isMobile"
    }),
    ...mapState("assets", {
      images: "images",
      url: "url"
    }),
    ...mapState("tasks", {
      categoryList: "categoryList",
      chainList: "chainList"
    }),
    id(): number {
      return Number(this.$route.params.id);
    },
    isAdd(): boolean {
      return !this.id;
    },
    group(): Group[] {
      const group = this.user.groupList.filter((group: Group) =>
        this.groupIds.includes(group.id)
      );
      return group;
    },
    chainName(): string {
      const chain = this.chainList.find(
        (i: TasksChain) => i.id === this.task.coin_type_id
      );
      if (chain && chain.id !== 1) {
        return "[" + chain.coin_name + "]";
      }
      return "";
    }
  },
  methods: {
    getTask() {
      dispatch
        .tasksGet(this.id)
        .then((task: Task) => {
          this.task = task;
          setTimeout(() => {
            document
              .querySelectorAll(".ql-editor .mention")
              .forEach(mention => {
                mention.addEventListener("click", (e: any) => {
                  const id = e.currentTarget.getAttribute("data-id");
                  const value = e.currentTarget.getAttribute("data-value");
                  if (id && value?.includes("@"))
                    window.open(this.$paths.users + id, "_blank");
                  if (id && value?.includes("#"))
                    window.open(this.$paths.dao + "?tag_ids=" + id, "_blank");
                });
              });
            document.querySelectorAll(".ql-editor a").forEach(a => {
              a.addEventListener("click", (e: any) => {
                e.preventDefault();
                const { className, href, download: fileName } = e.currentTarget;
                if (className === "file" || fileName) {
                  dispatch
                    .filesGet([href], this.id)
                    .then(res => {
                      download(res[0]?.sign_url || href, fileName);
                    })
                    .catch(() => {
                      download(href, fileName);
                    });
                } else {
                  window.open(href, "_blank");
                }
              });
            });
          }, 500);
          dispatch
            .tasksGetGroupIds((task.tags || []).map(i => i.tag_id || 0))
            .then(res => {
              this.groupIds = res.group_ids;
            });
          this.getSignImages();
          share({
            title: task.title,
            desc: task.content
              ? task.content.replace(/(<([^>]+)>)/gi, "")
              : "Future Art Community",
            link: window.location.href,
            imgUrl:
              task.medias && task.medias.length
                ? task.medias[0].url
                : this.url.logo
          });
        })
        .catch(() => {
          if (!this.user.isSignIn) {
            this.$message.destroy();
            dispatch.userSignIn();
          }
        });
    },
    getSignImages() {
      const imageList =
        this.task.content.match(/https?:\/\/((?!").)+?aliyuncs.+?"/g) || [];
      const fileList = imageList.map((image: string) => image.replace('"', ""));
      if (fileList.length) {
        dispatch.filesGet(fileList, this.id).then(res => {
          fileList.map(url => {
            res.find((item: any) => {
              if (url.includes(item.request_url)) {
                this.task.content = this.task.content.replace(
                  url,
                  item.sign_url
                );
                return true;
              }
            });
          });
        });
      }
    },
    onVote(ids: number[]) {
      dispatch
        .tasksVote({ id: this.id, ids })
        .then(() => {
          this.$message.info("" + this.$t("task.voteSuccess"));
          this.getTask();
        })
        .catch(() => {
          if (!this.user.isSignIn) {
            this.$message.destroy();
            dispatch.userSignIn();
          }
        });
    },
    onDone() {
      const canReward =
        this.allow("task", "reward") && this.task.max_reward_number;
      const { enable, is_multi, max_num } = this.task.vote_conf;
      this.$confirm({
        title: this.$t(
          canReward ? "task.closeRewardConfirm" : "task.closeConfirm"
        ),
        centered: true,
        okText: "" + this.$t("task.ok"),
        cancelText: "" + this.$t("task.cancel"),
        onOk: () => {
          dispatch
            .tasksPatch({
              ...this.task,
              vote_conf: {
                enable: enable || false,
                is_multi: is_multi || false,
                max_num: max_num || "1"
              },
              status: "closed"
            })
            .then(() => {
              this.getTask();
              this.$message.info("" + this.$t("task.closeSuccess"));
              if (canReward) {
                dispatch.tasksReward(this.task).then(() => {
                  this.$message.info("" + this.$t("task.rewardSuccess"));
                });
              }
            });
        }
      });
    },
    onBack() {
      this.$router.push(this.$paths.dao);
      // this.isFormTasks
      //   ? this.$router.back()
      //   : this.$router.push(this.$paths.dao);
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm: any) => {
      vm.isFormTasks = from.path === vm.$paths.dao;
    });
  },
  mounted() {
    if (this.id) {
      this.getTask();
    } else {
      this.$router.push(this.$paths.dao);
    }
  },
  beforeDestroy() {
    share({
      title: "Angry Miao",
      desc: "Future Art Community",
      link: window.location.href,
      imgUrl: this.url.logo
    });
  }
});
